<script>
import { onMounted, ref, watchEffect } from 'vue';
import TextSlicer from 'text-slicer';
import useWheel from '../store/useWheel';

export default {
  name: 'GainMessage',
  setup() {
    const wheelStore = useWheel();

    const gainMessageElOne = ref(null);
    const gainMessageElTwo = ref(null);
    const gainMessageElThree = ref(null);

    const elements = [gainMessageElOne, gainMessageElTwo, gainMessageElThree];

    onMounted(() => {
      elements.forEach(elementRef => {
        if (elementRef.value) {
          const textSlicer = new TextSlicer({
            container: elementRef.value,
            cssVariables: true,
            mode: 'chars',
          });

          textSlicer.init();
        }
      });
    });

    watchEffect(() => {
      if (wheelStore.first) {
        document.body.classList.add('first-spin-complete');

        gainMessageElOne.value?.classList.add('slide-in');

        setTimeout(() => {
          gainMessageElTwo.value?.classList.replace('slide-null', 'slide-out');
        }, 300);
      }

      if (wheelStore.second) {
        document.body.classList.replace('first-spin-complete', 'second-spin-complete');

        gainMessageElTwo.value?.classList.replace('slide-out', 'slide-in');

        setTimeout(() => {
          gainMessageElThree.value?.classList.replace('slide-null', 'slide-out');
        }, 300);
      }
    });

    return {
      gainMessageElOne,
      gainMessageElTwo,
      gainMessageElThree,
    };
  },
};
</script>

<template lang="pug">
  .gain-title
    .gain-title__item Крути ещё
    .gain-title__item Твой бонус

  .gain-message
    .gain-message__item(ref="gainMessageElOne").gain-message-one Крути и выигрывай!
    .gain-message__item(ref="gainMessageElTwo").gain-message-two.slide-null 150 000₽
    .gain-message__item(ref="gainMessageElThree").gain-message-three.slide-null 150 000₽ + 1000 FS
</template>

<style lang="scss">
@import "../assets/scss/base/mixins";

.gain-title {
  position: relative;
  width: fit-content;
  margin: 0 auto calc(var(--v-offset-sm) / 2);
  filter: drop-shadow(0 1px 2px rgb(0, 0, 0));

  &__item {
    color: White;
    line-height: 1;
    font-size: 2.6em;
    font-weight: 860;
    opacity: 0;
    transition: opacity 1025ms 925ms ease-out;
    text-align: center;

    @include min(tablet-portrait) {
      font-size: 3.2em;
    }

    &:nth-child(1) {
      position: absolute;
      inset: 0 0 auto;

      body.first-spin-complete & {
        opacity: 1;
      }
    }

    &:nth-child(2) {
      body.first-spin-complete & {
        opacity: 0;
      }

      body.second-spin-complete & {
        opacity: 1;
      }
    }
  }
}

.gain-message {
  position: relative;
  filter: drop-shadow(0 1px 2px rgb(0, 0, 0));

  &__item {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    color: White;
    line-height: 1;
    font-size: 3.8em;
    font-weight: 900;

    @include min(tablet-portrait) {
      font-size: 4.8em;
    }

    &:nth-child(1) {

    }

    &:nth-child(2) {
      color: Gold;

      transform: rotate(-2.5deg);
      animation: libration 1250ms infinite alternate ease-in-out;

      @-webkit-keyframes libration {
        to {
          transform: rotate(2.5deg);
        }
      }
    }

    &:nth-child(3) {
      color: Gold;

      transform: rotate(-1.5deg);
      animation: libration 1250ms infinite alternate ease-in-out;

      @-webkit-keyframes libration {
        to {
          transform: rotate(1.5deg);
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: -1em;
    }

    .char {
      will-change: transform;
      backface-visibility: hidden;
    }

    .word,
    .char {
      display: inline-block;
    }

    .whitespace {
      white-space: pre;
    }

    &.slide-null .char {
      transform: translateY(-0.5em) rotateX(-90deg);
    }

    &.slide-in .char {
      transform: translateY(0) rotateX(0deg);
      animation: slide-in 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-in {
        to {
          transform: translateY(-0.5em) rotateX(-90deg);
        }
      }
    }

    &.slide-out .char {
      transform: translateY(0.5em) rotateX(90deg);
      animation: slide-out 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-out {
        to {
          transform: translateY(0) rotateX(0deg);
        }
      }
    }
  }
}
</style>
