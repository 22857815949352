<script>
import { ref, onMounted } from 'vue';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import WheelFortune from 'wheel-fortune';
import useWheel from '../store/useWheel';

gsap.registerPlugin(CustomEase);
WheelFortune.registerGSAP(gsap, CustomEase);

export default {
  name: 'WheelFortune',
  setup() {
    const wheelStore = useWheel();
    const containerEl = ref(null);
    const segmentsEl = ref(null);
    const buttonEl = ref(null);

    onMounted(() => {
      const spinStates = [
        { stopSegment: 4, callback: () => wheelStore.setFirst(true) },
        { stopSegment: 1, callback: () => wheelStore.setSecond(true) },
      ];

      const wheel = new WheelFortune({
        containerEl: containerEl.value,
        segmentsEl: segmentsEl.value,
        buttonEl: buttonEl.value,
        spinStates,
        rotationCount: 4,
        segmentCount: 6,
        wheelLibration: true,
      });

      wheel.init();

      function animateHue() {
        const element = containerEl.value;
        const duration = 4000;
        const startHue = 0;
        const endHue = 360;
        let startTime = null;

        const updateHue = (currentTime) => {
          startTime ??= currentTime;

          const elapsedTime = currentTime - startTime;
          const progress = Math.min(elapsedTime / duration, 1);
          const hue = startHue + (endHue - startHue) * progress;

          element.style.setProperty('--hue', hue.toFixed(2));

          if (progress < 1) {
            requestAnimationFrame(updateHue);
          } else {
            startTime = null;
            requestAnimationFrame(updateHue);
          }
        };

        requestAnimationFrame(updateHue);
      }

      animateHue();
    });

    return {
      containerEl,
      segmentsEl,
      buttonEl,
    };
  },
};
</script>

<template lang="pug">
  .wheel-fortune(ref="containerEl")
    .wheel-fortune__segments(ref="segmentsEl")
      ImageContainer(file="wheel_sections")
    .wheel-fortune__frame
      ImageContainer(file="wheel_frame")
    .wheel-fortune__arrow
      ImageContainer(file="wheel_diamond")
    .wheel-fortune__decor
    .wheel-fortune__button(ref="buttonEl")
      SvgIcon(name="i-cartwheel")
      ImageContainer(file="button_background")
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

@mixin positioning {
  position: absolute;
  inset: 0;
  margin: auto;
  border-radius: 100%;
}

.wheel-fortune {
  --z-index-frame: 1;
  --z-index-segments: 2;
  --z-index-blurring: 2;
  --z-index-blackout: 2;
  --z-index-diamond: 3;
  --z-index-button: 4;
  --z-index-segments-border: 5;
  --z-index-arrow: 6;
  --wheel-size: max(600px, 60vmin);
  --rotating-area-size: 87.6%;
  --blackout-color: rgb(11, 3, 34);

  position: relative;
  width: var(--wheel-size);
  height: var(--wheel-size);
  margin: 0 0 calc(((var(--wheel-size) / 2) - 140px) * -1);
  filter: drop-shadow(0 0 5vmin hsla(var(--hue) 60% 40% / 0.7));

  @include min(phone-landscape) {
    --wheel-size: max(540px, 54vmin);

    margin: auto;
  }

  &::after {// Рамка скрывающая края изображения с сегментами, чтобы скрыть дефекты размтия при вращении
    @include positioning;

    content: '';
    z-index: var(--z-index-segments-border);
    width: var(--rotating-area-size);
    height: var(--rotating-area-size);
    box-shadow: 0 0 0 clamp(4px, 1vmin, 6px) inset #1f0d40;
    pointer-events: none;
  }

  &__segments {
    @include positioning;

    width: var(--rotating-area-size);
    height: var(--rotating-area-size);
    z-index: var(--z-index-segments);
    overflow: hidden;
    will-change: transform;
    pointer-events: none;

    .svg-icon--wheel {
      width: 100%;
      height: 100%;
    }
  }

  &__frame {
    @include positioning;

    overflow: hidden;
    z-index: var(--z-index-frame);
  }

  &__decor {
    pointer-events: none;

    &::after {
      @include positioning;

      width: var(--rotating-area-size);
      height: var(--rotating-area-size);
      z-index: var(--z-index-blurring);
      content: '';
      transform: translate3d(0, 0, 0);
      backdrop-filter: blur(var(--blurring));
      backface-visibility: hidden;
      pointer-events: none;
    }

    &::before {
      --cutout: calc(100% - (100% / var(--blackout-angle, 8)));
      --color: var(--blackout-color);

      @include positioning;

      opacity: var(--blackout-opacity, 0);
      width: var(--rotating-area-size);
      height: var(--rotating-area-size);
      z-index: var(--z-index-blackout);
      content: '';
      background: conic-gradient(var(--color) var(--cutout), transparent var(--cutout));
      transform: rotate(calc((360deg / var(--blackout-angle, 8)) / 2));
      pointer-events: none;
    }
  }

  &__arrow {
    position: absolute;
    inset: -3.4% 0 auto;
    margin: auto;
    z-index: var(--z-index-arrow);

    width: 12.8%;
    height: 12.8%;
    filter: drop-shadow(0 0.6vmin 0.3vmin rgba(0, 0, 0, 0.7));
  }

  &__button {
    @include positioning;

    overflow: hidden;
    z-index: var(--z-index-button);
    width: 24%;
    height: 24%;
    cursor: pointer;
    transition: box-shadow 125ms ease-in-out;
    -webkit-tap-highlight-color: transparent;
    box-shadow:
      0 0 0 clamp(10px, 1.2vmin, 24px) rgba(31, 13, 64, 0.4),
      0 clamp(5px, 0.6vmin, 12px) clamp(10px, 1.2vmin, 24px) 0 rgba(255, 165, 70, 0.75),
      0 clamp(-2.5px, -0.3vmin, -6px) clamp(10px, 1.2vmin, 24px) 0 rgba(255, 165, 70, 0.55);
    animation: filter-animation 4s infinite;

    .svg-icon {
      position: absolute;
      inset: 0;
      margin: auto;
      width: 42%;
      height: 42%;
      fill: White;
      transform: rotate(-3.5deg);
      animation: libration 750ms infinite alternate ease-in-out;

      @-webkit-keyframes libration {
        to {
          transform: rotate(3.5deg);
        }
      }
    }

    .is-spinning &,
    .end-last-spin & {
      pointer-events: none;
      filter: grayscale(10) hue-rotate(0);

      .svg-icon {
        animation: none;
        opacity: 0.25;
      }
    }

    body.first-spin-complete & {

    }
  }
}
</style>
