<script>
import { onMounted, ref, watchEffect } from 'vue';
import DialogLite from 'dialog-lite';
import { lock } from 'tua-body-scroll-lock';
import useWheel from '../store/useWheel';

export default {
  name: 'DialogWindow',
  setup() {
    const wheelStore = useWheel();

    const dialogLiteEl = ref(null);

    let dialogLite;

    onMounted(() => {
      dialogLite = new DialogLite({
        closingButton: true,
        closingBackdrop: true,
      });

      dialogLite.init();
    });

    watchEffect(() => {
      if (wheelStore.second) {
        setTimeout(() => {
          lock(dialogLiteEl.value, { overflowType: 'clip' });

          dialogLite.open();
        }, 3000);
      }
    });

    return { dialogLiteEl };
  },
};
</script>

<template lang="pug">
  .dialog-lite.dialog-lite--out(ref="dialogLiteEl")(role="dialog" aria-modal="true")
    .dialog-lite__backdrop
    .dialog-lite__container
      .dialog-lite__container-inner
        .dialog-lite-close-button(aria-label="Закрыть" tabindex="0")
          SvgIcon(name="i-close")

        .demo-content Dialog
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.demo-content {
  display: grid;
  place-content: center;
  width: 250px;
  height: 250px;
  background-color: LightCoral;
}
</style>
