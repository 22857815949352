<script>
import PageBackground from './components/PageBackground';
import WheelFortune from './components/WheelFortune';
import PaymentBrands from './components/PaymentBrands';
import DialogWindow from './components/DialogWindow';
import LogotypeChampion from './components/LogotypeChampion';
import ParticlesBackground from './components/ParticlesBackground';
import GainMessage from './components/GainMessage';

export default {
  name: 'App',
  components: {
    PageBackground,
    WheelFortune,
    PaymentBrands,
    DialogWindow,
    LogotypeChampion,
    ParticlesBackground,
    GainMessage,
  },
};
</script>

<template lang="pug">
  .page
    .page__logo
      LogotypeChampion
    .page__gain
      GainMessage
    .page__wheel
      WheelFortune
    .page__payment
      PaymentBrands

  ImageContainer(file="page_bg", styleClass="page-bg")
  ParticlesBackground
  DialogWindow
</template>

<style lang="scss" scoped>
@import "assets/scss/base/mixins";

.page {
  overflow: hidden;
  position: relative;
  display: grid;
  gap: 0;
  place-content: space-between center;
  justify-items: center;
  z-index: var(--z-index-page);

  @include min(phone-landscape) {

  }

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  &__logo {
    padding-top: 3vmin;
  }

  &__gain {
    padding: calc(var(--v-offset-sm) * 1.25) 0;
  }

  &__wheel {
    width: fit-content;

  }

  &__payment {
    position: absolute;
    inset: auto 0 0;
    z-index: var(--z-index-page-payment);
    margin: auto;

    @include min(phone-landscape) {
      position: unset;
      inset: unset;
    }
  }
}
</style>
