interface ImageData {
  alt: string;
  width: number;
  height: number;
  loading: 'eager' | 'lazy';
  decoding: 'auto' | 'sync' | 'async';
  src: URL;
}

interface SourceSet {
  [key: number]: URL;
}

interface ImageSources {
  sizes: string;
  avif: SourceSet;
  webp: SourceSet;
  jpeg: SourceSet;
}

interface ImageObject {
  img: ImageData;
  source: ImageSources;
  background?: string;
}

interface ImagesData {
  wheel_sections: ImageObject;
  wheel_frame: ImageObject;
  page_bg: ImageObject;
  button_background: ImageObject;
  wheel_diamond: ImageObject;
}

const imagesData: ImagesData = {
  wheel_sections: {
    img: {
      alt: 'wheel fortune',
      width: 900,
      height: 900,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/wheel_sections.png?as=jpeg&width=900', import.meta.url),
    },
    source: {
      sizes: '(max-width: 900px) 100vw, 900px',
      avif: {
        450: new URL('../assets/images/wheel_sections.png?as=avif&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_sections.png?as=avif&width=600', import.meta.url),
        750: new URL('../assets/images/wheel_sections.png?as=avif&width=750', import.meta.url),
        900: new URL('../assets/images/wheel_sections.png?as=avif&width=900', import.meta.url),
      },
      webp: {
        450: new URL('../assets/images/wheel_sections.png?as=webp&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_sections.png?as=webp&width=600', import.meta.url),
        750: new URL('../assets/images/wheel_sections.png?as=webp&width=750', import.meta.url),
        900: new URL('../assets/images/wheel_sections.png?as=webp&width=900', import.meta.url),
      },
      jpeg: {
        450: new URL('../assets/images/wheel_sections.png?as=jpeg&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_sections.png?as=jpeg&width=600', import.meta.url),
        750: new URL('../assets/images/wheel_sections.png?as=jpeg&width=750', import.meta.url),
        900: new URL('../assets/images/wheel_sections.png?as=jpeg&width=900', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  wheel_frame: {
    img: {
      alt: '',
      width: 1400,
      height: 1400,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/wheel_frame.png?as=jpeg&width=1400', import.meta.url),
    },
    source: {
      sizes: '(max-width: 1000px) 100vw, 1000px',
      avif: {
        450: new URL('../assets/images/wheel_frame.png?as=avif&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_frame.png?as=avif&width=600', import.meta.url),
        1000: new URL('../assets/images/wheel_frame.png?as=avif&width=1000', import.meta.url),
        1400: new URL('../assets/images/wheel_frame.png?as=avif&width=1400', import.meta.url),
      },
      webp: {
        450: new URL('../assets/images/wheel_frame.png?as=webp&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_frame.png?as=webp&width=600', import.meta.url),
        1000: new URL('../assets/images/wheel_frame.png?as=webp&width=1000', import.meta.url),
        1400: new URL('../assets/images/wheel_frame.png?as=webp&width=1400', import.meta.url),
      },
      jpeg: {
        450: new URL('../assets/images/wheel_frame.png?as=jpeg&width=450', import.meta.url),
        600: new URL('../assets/images/wheel_frame.png?as=jpeg&width=600', import.meta.url),
        1000: new URL('../assets/images/wheel_frame.png?as=jpeg&width=1000', import.meta.url),
        1400: new URL('../assets/images/wheel_frame.png?as=jpeg&width=1400', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  page_bg: {
    img: {
      alt: '',
      width: 2800,
      height: 2800,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/page_bg.png?as=jpeg&width=2800', import.meta.url),
    },
    source: {
      sizes: '(max-width: 1800px) 100vw, 1800px',
      avif: {
        1000: new URL('../assets/images/page_bg.png?as=avif&width=1000', import.meta.url),
        1400: new URL('../assets/images/page_bg.png?as=avif&width=1400', import.meta.url),
        1800: new URL('../assets/images/page_bg.png?as=avif&width=1800', import.meta.url),
        2800: new URL('../assets/images/page_bg.png?as=avif&width=2800', import.meta.url),
      },
      webp: {
        1000: new URL('../assets/images/page_bg.png?as=webp&width=1000', import.meta.url),
        1400: new URL('../assets/images/page_bg.png?as=webp&width=1400', import.meta.url),
        1800: new URL('../assets/images/page_bg.png?as=webp&width=1800', import.meta.url),
        2800: new URL('../assets/images/page_bg.png?as=webp&width=2800', import.meta.url),
      },
      jpeg: {
        1000: new URL('../assets/images/page_bg.png?as=jpeg&width=1000', import.meta.url),
        1400: new URL('../assets/images/page_bg.png?as=jpeg&width=1400', import.meta.url),
        1800: new URL('../assets/images/page_bg.png?as=jpeg&width=1800', import.meta.url),
        2800: new URL('../assets/images/page_bg.png?as=jpeg&width=2800', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  button_background: {
    img: {
      alt: '',
      width: 300,
      height: 300,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/button_background.png?as=jpeg&width=300', import.meta.url),
    },
    source: {
      sizes: '(max-width: 300px) 100vw, 300px',
      avif: {
        200: new URL('../assets/images/button_background.png?as=avif&width=200', import.meta.url),
        300: new URL('../assets/images/button_background.png?as=avif&width=300', import.meta.url),
      },
      webp: {
        200: new URL('../assets/images/button_background.png?as=webp&width=200', import.meta.url),
        300: new URL('../assets/images/button_background.png?as=webp&width=300', import.meta.url),
      },
      jpeg: {
        200: new URL('../assets/images/button_background.png?as=jpeg&width=200', import.meta.url),
        300: new URL('../assets/images/button_background.png?as=jpeg&width=300', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
  wheel_diamond: {
    img: {
      alt: '',
      width: 100,
      height: 100,
      loading: 'eager',
      decoding: 'auto',
      src: new URL('../assets/images/wheel_diamond.png?as=jpeg&width=100', import.meta.url),
    },
    source: {
      sizes: '(max-width: 100px) 100vw, 100px',
      avif: {
        100: new URL('../assets/images/wheel_diamond.png?as=avif&width=100', import.meta.url),
      },
      webp: {
        100: new URL('../assets/images/wheel_diamond.png?as=webp&width=100', import.meta.url),
      },
      jpeg: {
        100: new URL('../assets/images/wheel_diamond.png?as=jpeg&width=100', import.meta.url),
      },
    },
    // background: 'data:image/svg+xml;base64,PHN2ZyB4wMo8L3N2Zz4=',
  },
};

export default imagesData;
