import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import SvgIcon from './components/SvgIcon.vue';
import ImageContainer from './components/ImageContainer.vue';

const app = createApp(App);

app.use(createPinia());
app.component('SvgIcon', SvgIcon);
app.component('ImageContainer', ImageContainer);
app.mount('#app');
