<script>
export default {
  name: 'PageBackground',
  setup() {

    return {};
  },
};
</script>

<template lang="pug">
  div.page-background
    svg.page-background__noise(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100vw 100vh")
      filter#noiseFilterBg
        feTurbulence(type="fractalNoise" baseFrequency="0.6" stitchTiles="stitch")
      rect(width="100%" height="100%" filter="url(#noiseFilterBg)")

    svg.page-background__blur(xmlns="http://www.w3.org/2000/svg")
      defs
        filter#goo
          feGaussianBlur(in="SourceGraphic" stdDeviation="10" result="blur")
          feColorMatrix(in="blur" mode="matrix" values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 18 -8" result="goo")
          feBlend(in="SourceGraphic" in2="goo")

    div.page-background__gradients
      each val in [1, 2, 3, 4, 5]
        div
</template>

<style lang="scss" scoped>
.page-background {
  --color-bg1: rgb(8, 10, 15);
  --color-bg2: rgb(0, 17, 32);
  --color1: 18, 113, 255;
  --color2: 107, 74, 255;
  --color3: 100, 100, 255;
  --color4: 50, 160, 220;
  --color5: 80, 47, 122;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;

  overflow: hidden;
  position: fixed;
  inset: 0;
  z-index: var(--z-index-page-background);
  margin: auto;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));

  &__blur {
    display: none;
  }

  &__noise {
    position: absolute;
    inset: 0;
    margin: auto;
    z-index: 1;
    width: 100%;
    height: 100%;
    mix-blend-mode: soft-light;
    opacity: 0.3;
  }

  &__gradients {
    filter: url(#goo) blur(40px);
    width: 100%;
    height: 100%;

    div {
      &:nth-child(1) {
        position: absolute;
        background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
        mix-blend-mode: var(--blending);
        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);
        transform-origin: center center;
        animation: move-vertical 30s ease infinite;
        opacity: 1;
      }

      &:nth-child(2) {
        position: absolute;
        background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
        mix-blend-mode: var(--blending);
        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);
        transform-origin: calc(50% - 400px);
        animation: move-in-circle 20s reverse infinite;
        opacity: 1;
      }

      &:nth-child(3) {
        position: absolute;
        background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
        mix-blend-mode: var(--blending);
        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2 + 200px);
        left: calc(50% - var(--circle-size) / 2 - 500px);
        transform-origin: calc(50% + 400px);
        animation: move-in-circle 40s linear infinite;
        opacity: 1;
      }

      &:nth-child(4) {
        position: absolute;
        background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
        mix-blend-mode: var(--blending);
        width: var(--circle-size);
        height: var(--circle-size);
        top: calc(50% - var(--circle-size) / 2);
        left: calc(50% - var(--circle-size) / 2);
        transform-origin: calc(50% - 200px);
        animation: move-horizontal 40s ease infinite;
        opacity: 0.7;
      }

      &:nth-child(5) {
        position: absolute;
        background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
        mix-blend-mode: var(--blending);
        width: calc(var(--circle-size) * 2);
        height: calc(var(--circle-size) * 2);
        top: calc(50% - var(--circle-size));
        left: calc(50% - var(--circle-size));
        transform-origin: calc(50% - 800px) calc(50% + 200px);
        animation: move-in-circle 20s ease infinite;
        opacity: 1;
      }
    }
  }

  @keyframes move-in-circle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes move-vertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }

  @keyframes move-horizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }
}
</style>
