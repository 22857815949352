<script>
export default {
  name: 'PaymentBrands',
};
</script>

<template lang="pug">
  .payment-brands
    .payment-brands__body
      SvgIcon(name="i-payment-visa")
      SvgIcon(name="i-payment-tether")
      SvgIcon(name="i-payment-sbp")
      SvgIcon(name="i-payment-piastrix")
      SvgIcon(name="i-payment-mir")
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.payment-brands {
  display: grid;
  place-content: center;

  @include max(phone-landscape) {
    background: rgba(125, 125, 125, 0.45);
    backdrop-filter: blur(15px);
  }

  &__body {
    position: relative;
    display: grid;
    grid-template: 1fr / repeat(5, 1fr);
    gap: 0 var(--v-offset-sm);
    padding: calc(var(--v-offset-sm) / 2) var(--v-offset-sm);
    height: 100%;
    filter: drop-shadow(0 1px 2px rgb(0, 0, 0));

    .svg-icon {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 34px;
      aspect-ratio: 10 / 5.4;
      fill: White;

      @include min(phone-landscape) {
        max-width: 52px;
        fill: White;
      }
    }
  }
}
</style>
