<script>
import { ref, onMounted } from 'vue';
import Iconly from 'iconly';

export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const iconName = ref('');

    const sprite = new URL('../assets/images/sprite.svg', import.meta.url);

    onMounted(async () => {
      const iconSet = new Iconly({
        file: sprite,
        version: '4.0',
        debug: false,
      });

      await iconSet.init();

      iconName.value = props.name;
    });

    return {
      iconName,
    };
  },
};
</script>

<template>
  <svg v-if="iconName" class="svg-icon">
    <use :href="`#${iconName}`" />
  </svg>
</template>

<style scoped>
.svg-icon {
    vertical-align: top;
    display: inline-block;
}
</style>
