<script>
export default {
  name: 'LogotypeChampion',
};
</script>

<template lang="pug">
  .logotype
    SvgIcon.logotype__star(name="i-logo-star")
    SvgIcon.logotype__champion(name="i-logo-text-champion")
    SvgIcon.logotype__slots(name="i-logo-text-slots")
</template>

<style lang="scss" scoped>
@import "../assets/scss/base/mixins";

.logotype {
  display: grid;
  grid-template: auto / auto;
  gap: 4px 8px;
  justify-items: unset;
  margin: auto;
  filter: drop-shadow(0 1px 2px rgb(0, 0, 0));

  @include min(phone-landscape) {
    gap: 8px 0;
    justify-items: center;
  }

  &__star {
    width: 5em;
    aspect-ratio: 1;

    @include max(phone-landscape) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }
  }

  &__champion {
    align-self: end;
    height: 1.6em;
    aspect-ratio: 20 / 2.8;
    fill: WhiteSmoke;

    @include max(phone-landscape) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }

  &__slots {
    height: 1.6em;
    aspect-ratio: 12 / 2.8;
    fill: WhiteSmoke;

    @include max(phone-landscape) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }
}
</style>
