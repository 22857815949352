<script>
export default {
  name: 'ParticlesBackground',
};
</script>

<template lang="pug">
  .particles
    .particles__one
    .particles__two
    .particles__three
    .particles__four
</template>

<style lang="scss" scoped>
@use 'sass:math';

$color-particle: DarkGray;
$spacing: 2560px;
$time-one: 60s;
$time-two: 120s;
$time-three: 180s;
$time-four: 600s;

@function particles($max) {
  $val: 0 0 $color-particle;

  @for $i from 1 through $max {
    $val: #{$val},
    math.random(math.div($spacing, 1px)) * 1px math.random(math.div($spacing, 1px)) * 1px $color-particle;
  }

  @return $val;
}

@mixin particles($max) {
  box-shadow: particles($max);
}

.particles {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-particles);
  width: $spacing;
  margin: auto;
  pointer-events: none;

  &__one {
    @include particles(600);

    animation: particles-animation $time-one linear infinite;
    height: 1px;
    width: 1px;

    &::after {
      @include particles(600);

      height: 1px;
      width: 1px;
    }
  }

  &__two {
    @include particles(200);

    animation: particles-animation $time-two linear infinite;
    height: 2px;
    width: 2px;

    &::after {
      @include particles(200);

      height: 2px;
      width: 2px;
    }
  }

  &__three {
    @include particles(100);

    animation: particles-animation $time-three linear infinite;
    height: 3px;
    width: 3px;

    &::after {
      @include particles(100);

      height: 3px;
      width: 3px;
    }
  }

  &__four {
    @include particles(400);

    animation: particles-animation $time-four linear infinite;
    height: 1px;
    width: 1px;

    &::after {
      @include particles(400);

      height: 1px;
      width: 1px;
    }
  }

  @keyframes particles-animation {
    to {
      transform: translateY($spacing * -1);
    }
  }
}
</style>
