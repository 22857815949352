import { defineStore } from 'pinia';

interface WheelState {
  first: boolean;
  second: boolean;
}

const useWheel = defineStore({
  id: 'useWheel',
  state: (): WheelState => ({
    first: false,
    second: false,
  }),
  actions: {
    setFirst(value: boolean) {
      this.first = value;
    },
    setSecond(value: boolean) {
      this.second = value;
    },
  },
});

export default useWheel;
